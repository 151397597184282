/* eslint-disable react/prop-types */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable import/order,no-underscore-dangle,react/destructuring-assignment,react/no-string-refs,prefer-template,react/no-find-dom-node,prefer-const,padding-line-between-statements,eqeqeq,no-useless-return,react/no-access-state-in-setstate,prefer-destructuring,class-methods-use-this,react/static-property-placement,no-useless-constructor */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CommonUtils from '../utils/CommonUtils';

import fdicStyles from '../../styles/FdicCompliance.scss';

function displayLogo(isInsured, config) {
    if (isInsured) {
        return (
            <img
                className={fdicStyles['fdic-logo-img']}
                src={config.complianceLogoPath}
                alt="Compliance Logo"
            />
        );
    }

    return <React.Fragment />;
}

function displayTextValue(isInsured, config) {
    if (isInsured) {
        return <div>{config.complianceInsuredStatement}</div>;
    }
    return <div>{config.complianceNonInsuredStatement}</div>;
}

function FdicComponent({ config, isInsured, showFDIC, isHomePage }) {
    const [isAccountLoaded, setAccountLoaded] = useState(!isHomePage);
    const handleAccountLoad = () => {
        setAccountLoaded(true);
    };
    useEffect(() => {
        if (isHomePage) {
            window.addEventListener('FDIC_ACCOUNTS_LOADED', handleAccountLoad);
            return () => {
                window.removeEventListener(
                    'FDIC_ACCOUNTS_LOADED',
                    handleAccountLoad,
                );
            };
        }
        return undefined;
    }, []);
    const isConfigPresent =
        CommonUtils.isConfigPresent(config.complianceLogoPath) &&
        CommonUtils.isConfigPresent(config.complianceInsuredStatement) &&
        CommonUtils.isConfigPresent(config.complianceNonInsuredStatement);
    if (isConfigPresent && showFDIC && isAccountLoaded) {
        return (
            <div
                className={
                    isHomePage
                        ? fdicStyles['parent-container-w-padding']
                        : fdicStyles['parent-container']
                }
            >
                <Helmet>
                    <link
                        rel="preconnect"
                        href="https://fonts.googleapis.com"
                    />
                    <link
                        rel="preconnect"
                        href="https://fonts.gstatic.com"
                        crossOrigin=""
                    />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap"
                        rel="stylesheet"
                    />
                </Helmet>
                <div className={fdicStyles['main-area']}>
                    <div className={fdicStyles['logo-area']}>
                        {displayLogo(isInsured, config)}
                    </div>
                    <div className={fdicStyles['text-area']}>
                        {displayTextValue(isInsured, config)}
                    </div>
                </div>
            </div>
        );
    }

    return <React.Fragment />;
}

export default FdicComponent;